<template>
  <div class="case-creation__step case-creation__Case-information">
    <div class="case-creation__medication">
      <div class="medication-card">
        <div class="case-creation__heading edit-view">
          <div class="title-with-icon">
            <span class="icon">
              <img
                src="@/assets/images/icons/icon-test-info.svg"
                alt="icon"
                class="img-fluid"
              />
            </span>
            <h3 class="title">Medications</h3>
          </div>
        </div>
        <div class="medication-content">
          <ul class="medication-block">
            <li>
              <p class="">(Note:- Add Medication One-by-one)</p>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="medication-list-block"
        v-if="
          getCaseInformation &&
          getCaseInformation.medication &&
          getCaseInformation.medication.length
        "
      >
        <ul class="medication-list">
          <li
            class="lists"
            v-for="(medication, index) in getCaseInformation.medication"
            :key="index"
          >
            {{ medication }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
export default {
  data() {
    return {
      MedicationPopup: false,
    };
  },
  mixins: [UserPermissionsHelper],
  methods: {
    goToEditForm() {
      this.$router.push({
        name: "Edit-Order-Info",
        params: { order_id: this.$route.params.order_id },
        query: this.$route.query,
      });
    },
  },
  computed: {
    ...mapGetters("orders", ["getCaseInformation"]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
  },
  mounted() {},
};
</script>
<style lang="scss">
.case-creation__medication {
  margin: 0 0 15px;

  .edit-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-with-icon {
      border-radius: 5px;
    }
  }

  .case-creation__card {
    .medication {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .medication-block {
    display: flex;
    align-items: center;
    li {
      .el-button {
        border: 0;
        padding: 0 10px;
        margin: 0 3px 0 10px;
        min-height: 36px;
        &.edit-btn {
          padding: 0;
          &:hover,
          &:active,
          &:focus {
            background-color: transparent;
          }
        }
        .edit-icon {
          display: flex;
          align-items: center;
          padding: 0;

          img {
            width: 15px;
            height: 15px;
          }
          p {
            color: #828282;
            font-size: 14px;
          }
        }
      }
    }
  }
  .medication-list-block {
    .view-medication {
      display: flex;
      margin: 5px 10px 0px 10px;
      justify-content: space-between;
      color: #828282;
      font-size: 13px;
      font-weight: 500;
      border-radius: 2px;
      padding: 5px 8px;
      align-content: center;
      .view-list {
        margin: 0;
        span {
          vertical-align: sub;
        }
      }
    }
  }
}
</style>
